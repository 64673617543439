import * as React from "react";
import { useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NativeSelect from "@mui/material/NativeSelect";

import "rsuite/styles/index.less";

import { BarLoader } from "react-spinner-animated";
import {
  DatePickerComponent,
  DateTimePickerComponent,
} from "@syncfusion/ej2-react-calendars";

import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { saveAs } from "file-saver";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLocation, useNavigate } from "react-router-dom";
import pic from "../assets/hblogo.png";
//import pic from "../assets/hblogo.png";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/Datasheet.css";
// import { Loader } from "rsuite";
import { Dimmer, Loader } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { color } from "@mui/system";
import { ListItemText } from "@mui/material";

export default function Datasheet() {
  // const [isopen,setisOpen]=React.useState("")
  const [masterId, setMasterId] = React.useState("");
  const [allClientId, setAllClientId] = React.useState("");
  const [selectAll, setSelectAll] = React.useState("All Entity");
  const [clientname, setclientname] = React.useState("");
  const [checkindt, setcheckindt] = React.useState(new Date());
  const [clientid, setclienid] = React.useState("");
 // const [checkoutdt, setcheckoutdt] = React.useState(new Date());
 const [checkoutdt, setcheckoutdt] = React.useState(() => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  return currentDate;
});
  const [newcheckoutdt, setnewcheckoutdt] = React.useState(new Date());
  const [cllogo, setcllogo] = React.useState("");

  const [alertvalue, setalertvalue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [openOnFocus, setopenonfocus] = React.useState(true);
  const dateinput1 = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [entityname, setentityname] = React.useState("");
  const [loadentityname, setloadentityname] = React.useState([]);
  const [errorvalue, seterrorvalue] = React.useState(false);
  const [entlength, setentlength] = React.useState("");
  const [clienttype, setclienttype] = React.useState("");
  const [disabled, setDisabled] = React.useState(false);
  const [chooseent, setchooseent] = React.useState("Choose the Entity Name");
  const [disablecomp, setdisablecomp] = React.useState(false);
  const [startValue, setStartValue] = React.useState("");
  const [endValue, setEndValue] = React.useState(null);
  const [disdate, setdisdate] = React.useState(false);
  const [msclientname, setmsclientname] = React.useState("");
  const [msclientlogo, setmsclientlogo] = React.useState("");
  let url =
  //`http://localhost:3000/api`
   //`https://powerbireportapi.staysimplyfied.com/API`;
   `https://datasheetapi.staysimplyfied.com/API`;
let urlerror = `https://powerbireportapi.staysimplyfied.com/API`
//`https://datasheetapi.staysimplyfied.com`;

  // const dateinput2 = React.useRef(null);
  const dateRef = useRef();
  const date1ref = useRef();

  //  dateinput1.current.focus()
  const chkindtchge = (date, dateString) => {
   
    setStartValue(date);
    setcheckindt(date._d);
    setEndValue(date);
  };

  const chkoutdtchge = (date, dateString) => {
  
    setcheckoutdt(date._d);
    setEndValue(date);
  };

  React.useEffect(() => {
    // setentityname('Choose the Entity Name')
    setdisdate(false);

    let current = document.querySelector("#ej2-datepicker_0");
    current.blur();
    let current1 = document.querySelector("#ej2-datepicker_43");
    current1.blur();
    let loadercomp = document.querySelector(".loader");
    loadercomp.classList.remove("active");
  }, []);
  const exceldownload = () => {
    setdisdate(false);
    if (entityname === "Choose the Entity Name") {
      setOpen1(true);
      seterrorvalue("Entity name is required");
      setLoading(false);
    } else {
      let loadercomp = document.querySelector(".dimmer");
      loadercomp.classList.add("active");
      let clsname = document.getElementsByClassName("active");

     

      const chkdtin = format(checkindt, "dd/MM/yyyy");
      const chkdtout = format(checkoutdt, "dd/MM/yyyy");
 

      let current = document.querySelector("#ej2-datepicker_0");
     
      if (entityname !== "All Entity") {
     
        const data = {
          checkindt: chkdtin,
          checkoutdt: chkdtout,
          clientid: clientid,
          clienttype: clienttype,
        };
        
        setLoading(true);
        // s http://localhost:3000/api/exceldownload
        // https://powerbireportapi.staysimplyfied.com/API/exceldownload
        //https://datasheetapi.staysimplyfied.com/API/exceldownload
        // let selectcomp=document.getElementById('demo-simple-select');
      

        const response = axios
          .post(`${url}/exceldownload`, data)
          .then((res) => {
       
            var dfile = `${res.data.Result}`;
            if (res.data == "No records found") {
              // setLoading(false)
              let loadercomp = document.querySelector(".dimmer");
              loadercomp.classList.remove("active");
              setOpen(true);
              setLoading(false);
              setalertvalue(res.data);
            } else {
              let loadercomp = document.querySelector(".dimmer");
              loadercomp.classList.remove("active");
              setdisdate(true);

              setLoading(false);
              saveAs(dfile, `Datasheet-${chkdtin}-${chkdtout}.xlsx`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
      
        // const chkdtin = format(checkindt, "dd/MM/yyyy");
        // const chkdtout = format(checkoutdt, "dd/MM/yyyy");
        const datati = {
          checkindt: chkdtin,
          checkoutdt: chkdtout,
          clientid: clientid,
          // "803,909,2624,2631,2643,2647,2648,2650,2699,2710,2711,2712,2713,2714",
          clienttype: clienttype,
        };
        const res = axios
          .post(`${url}/exceldownloadti`, datati)
          .then((res) => {
          
            var dfile = `${res.data.Result}`;
            if (res.data == "No records found") {
              // setLoading(false)
              let loadercomp = document.querySelector(".dimmer");
              loadercomp.classList.remove("active");
              setOpen(true);
              setLoading(false);
              setalertvalue(res.data);
            } else {
              let loadercomp = document.querySelector(".dimmer");
              loadercomp.classList.remove("active");
              setdisdate(true);

              setLoading(false);
              saveAs(dfile, `Datasheet-${chkdtin}-${chkdtout}.xlsx`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // });
  //  const location = useLocation();

  
  React.useEffect(() => {
    setentityname("Choose the Entity Name");

    setopenonfocus(true);
    const loadstates = async () => {
      // const input =`https://powerbireport.staysimplyfied.com/?31AD9767-077D-4545-A8BC-39596D412727`
      const search = window.location.href;

    

      const [urlsplit, rowidsplit] = search.split("?");
      // const [newurlsplit,setnewurlsplit]=urlsplit.split('%')
      
      const dataurl = {
        durl: rowidsplit,
      };

      //  const redirect= window.location.href = 'http://localhost:8000/404'
      // https://powerbireportapi.staysimplyfied.com/API/clogodownload
      //  http://localhost:3000/api/exceldownload
      const response = await axios
        .post(`${url}/clogodownload`, dataurl)
        .then((res) => {
         
  
          if (
            res.data?.number == 8169 ||
            res.data?.recordsets[0][0]?.Status == "failure"
          ) {
           //change window.location.href = "https://powerbireport.staysimplyfied.com/404";
           window.location.href = `${urlerror}/404`;
          }
         
          setentityname(res.data?.recordsets);
          
          let len = res.data?.recordset.length;
          
          setentlength(len);

          if (len <= 1) {
            // setdisablecomp(true);
            document.querySelector(".MuiInputBase-formControl").style.display =
              "none";
            // setentityname(res.data.recordset[0].ClientName)
          }

          if (len > 1) {
            setentityname("Choose the Entity Name");
          }

          // entityname.map((entity) => {
       
          //   setSelectAll(entity.ClientId);
          // });

          // setentityname(res.data.recordset[0].ClientName)
        

          setclienttype(res.data?.recordset[0]?.clienttype);

          setloadentityname(res.data?.recordset);
         
          const allClients = res.data?.recordset.map((fetch) => fetch.ClientId);
          const stringClientId = allClients.join(",");
         
          setAllClientId(stringClientId);
         

          setclienid(res.data.recordset[0]?.ClientId);
       
          // if(res.data.recordsets[1][0].msclientname!=="" && res.data.recordsets[0][0].clienttype!=="client")
          // {
          
          //   setclientname(res.data.recordsets[1][0].msclientname)
          // }
          // else{

         
          //   setclientname(res.data.recordsets[0][0].ClientName && res.data.recordsets[0][0].clienttype!=="client")
          // }
          // if(res.data.recordsets[1][0].clientlogo!==""&&res.data.recordsets[1][0].clientlogo!==undefined)
          // {
          

          // }else{
         
          // setcllogo(res.data.recordsets[0][0].ClientLogo)
          // }
       
          if (res.data.recordsets[0][0].clienttype == "Masterclient") {
            setmsclientname(res.data.recordsets[1][0]?.msclientname);
            setmsclientlogo(res.data.recordsets[1][0]?.clientlogo || pic);
            setMasterId(res.data.recordsets[1][0]?.id);
            
           
          }
          if (res.data.recordsets[0][0]?.clienttype == "client") {
            setclientname(res.data.recordsets[0][0]?.ClientName);
           
            setcllogo(res.data.recordsets[0][0]?.ClientLogo || pic);
          }
        })
        .catch((error) => {
          console.log(error);
        });

     // setcheckoutdt(new Date());
      setcheckindt(new Date());
      // const div = document.getElementsByClassName('');
    };

    loadstates();
   
  }, [clientname]);

  const clear = (date) => {
    // setentityname('Choose the Entity Name')
    setcheckindt(new Date());
    
    setcheckoutdt(new Date());
  
    setentityname("Choose the Entity Name");
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };
  const handleClose1 = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen1(false);
    }
  };

  const handleClick = (event) => {
    let current = document.querySelector("#ej2-datepicker_0");
    let current1 = document.querySelector(".e-input-group-icon");
   
    current1.addEventListener("çlick", current.blur());
    setTimeout(() => {
      // current1.blur()
    }, 100);
  };

  const handleClick2 = (event) => {
    let current1 = document.querySelector("#ej2-datepicker_43");
    // current.blur();

    setTimeout(() => {
      current1.blur();
    }, 100);
  };

  //   onFocus=(args)=> {

  // }

  //  const newchkt=
  //  function handleChange(event) {
  //   setcheckindt(event.target.value,"dd/mm/yyyy")

 


  // }

  // const handleChange=(date) =>{
  //   setcheckindt(date)
  //   // setcheckoutdt(date)
  // }

  // const clientIdsString = loadentityname
  //   .map((fetch) => fetch.clientId)
  //   .join(", ");

 

  return (
    <>
      {/* <Loader center size="lg" content="Processing"  /> */}

      {/* // <Loader center size="lg" content="Processing"  /> */}

      <Card
        className="rightcard mb-3 "
        sx={{
          borderRadius: "16px",
          background: "#edeceb",
          marginBottom: "100px",
        }}
      >
        <CardContent>
          {/* <div className="d-inline float-end">
      
       </div> */}

          <div className="d-flex justify-content-center ">
            {/* <img src={pic} alt="logo" className='float-start p-2'  style={{maxheight:"150px",maxWidth:"150px"}} /> */}

            <Typography
              sx={{ fontSize: 20, fontWeight: "bold", textAlign: "center" }}
              className="mt-sm-3 mb-sm-2"
              color="#33559b"
            >
              {clientname || msclientname} - Datasheet Download
            </Typography>
            <hr></hr>
          </div>

          <Card className="shadow ms-sm-1 " sx={{ borderRadius: "16px" }}>
            {loading && (
              <div className="active" style={{ width: "100%", height: "100%" }}>
                {"  "}{" "}
              </div>
            )}
            <CardContent className="toptd">
              <div className="row  ">
                <div className="col-sm-2  col-md-6 col-lg-8 col-xl-8 "></div>

                <div className="col-sm-4 col-md-6 col-lg-4 mt-sm-3 col-xl-4">
                  <FormControl
                    vx="true"
                    variant="standard"
                    disabled={disablecomp}
                  >
                    <Select
                      labelid="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Entity Name"
                      sx={{ width: "315px" }}
                      value={entityname}
                      onChange={(event) => {

                        setentityname(event.target.value);
                      }}
                    >
                      <MenuItem
                        value={chooseent}
                        style={{
                          // fontWeight: "bold",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <p
                          style={{
                            overflow: "hidden",
                            margin: "0",
                           // position: "absolute",
                            top: "50%",
                            width: "100%",
                            // -ms-transform: translateY(-50%);
                           // transform: " translateY(-50%)",
                          }}
                        >
                          Choose the Entity Name
                        </p>
                      </MenuItem>

                      {masterId === "969" ? (
                        <MenuItem
                          name={selectAll}
                          onClick={() => {
                            setclienid(allClientId);
                            // setSelectAll(e.target.value);
                          }}
                          value={selectAll}
                          style={{
                            fontWeight: "bold",
                            width: "100%",
                          }}
                        >
                          {selectAll}
                        </MenuItem>
                      ) : (
                        <div></div>
                      )}


                      {/* <option  value={""}>Choose the Entity Name</option> */}
                      {loadentityname.map((entity) => (
                        <MenuItem
                          key={entity.ClientName}
                          name={entity.ClientName}
                          onClick={(event) => {
                            setclienid(entity.ClientId);
                        
                          }}
                          value={entity.ClientName}
                          style={{ width: "100%" }}
                        >
                          {/* <ListItemText
                            primary={entity.ClientName}
                          ></ListItemText> */}
                          {entity.ClientName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>

              {/* <h5 className="card-title d-inline " style={{fontWeight:"bold",color:"#f1841c"}}>Travel Details</h5> */}
              <div className="row mt-sm-3 ">
                <div className="col-sm-2  col-md-2 col-lg-2 col-xl-2 ">
                  <img
                    src={cllogo || msclientlogo}
                    height={"70px"}
                    style={{ maxWidth: "200px" }}
                  />
                </div>
                <label
                  htmlFor="colFormLabelSm"
                  className="mt-3 col-sm-2 col-lg-1 col-xl-1 col-md-2 col-form-label col-form-label-sm"
                  style={{ width: "170px" }}
                >
                  Check-Out From Date :
                </label>
                <div className="mt-3 col-sm-2 col-md-3 col-lg-2 col-xl-2 ">
                  <DatePickerComponent
                    value={checkindt}
                    onChange={(date) => {
                      setcheckindt(date.value);
                      // setnewcheckoutdt(date.value)
                      
                      const newCheckoutDate = new Date(date.value);
                      newCheckoutDate.setMonth(date.value.getMonth() + 1);
                      setcheckoutdt(newCheckoutDate);
                    }}
                    format="dd-MM-yyyy"
                    // onClick={handleClick}
                    allowEdit={false}
                    showClearButton={false}
                    onSelect={handleClick}
                    openOnFocus={true}
                    onClick={handleClick}
                    required={true}
                  />
                </div>
                <label
                  htmlFor="colFormLabelSm"
                  className="col-sm-2 col-lg-1  col-xl-1 col-md-2  col-form-label col-form-label-sm mt-lg-3 bkdateto"
                  style={{ width: "170px" }}
                >
                  Check-Out To Date :
                </label>
                <div className="col-sm-4 mt-xl-3 col-md-2 col-lg-2 col-xl-2  bkdateto">
                  <DatePickerComponent
                    value={checkoutdt}
                    onChange={(date) => setcheckoutdt(date.value)}
                    // max={
                    //   new Date(
                    //     checkindt.getFullYear(),
                    //     checkindt.getMonth(),
                    //     90
                    //   )
                    // }
                    max={new Date(
                      checkindt.getFullYear(),
                      checkindt.getMonth() + 3,
                      checkindt.getDate()
                    )}
                    min={checkindt}
                    format="dd-MM-yyyy"
                    allowEdit={false}
                    showClearButton={false}
                    openOnFocus={true}
                    onClick={handleClick2}
                  />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-2 mt-sm-3  col-xl-2">
                  <Button
                    type="submit"
                    variant="contained"
                    className=" ms-lg-5 ms-xl-5 dbtn"
                    onClick={exceldownload}
                    startIcon={<DownloadForOfflineIcon />}
                  >
                    Download
                  </Button>
                  {/* onClick={exceldownload} */}
                </div>
                <div className="mt-sm-3 col-sm-4 col-md-3 col-lg-1 col-xl-1 ">
                  <Button
                    variant="contained"
                    className="mt-md-0 mt-sm-3 clrbtn"
                    color="error"
                    sx={{ width: "140px" }}
                    onClick={clear}
                    startIcon={<ClearIcon />}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </CardContent>

        <Dialog
          open={open1}
          onClose={handleClose1}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          // onBackdropClick={handleClose}
          //  disableEscapeKeyDown
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ background: "#d70738", color: "#fff" }}
          >
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="text-center mt-sm-4"
              sx={{ width: "400px", color: "black" }}
            >
              {/* {errorvalue} */} Entity Name is required
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Button variant="contained" color="error" onClick={handleClose1}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          // onBackdropClick={handleClose}
          disableEscapeKeyDown
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ background: "#d70738", color: "#fff" }}
          >
            {"Message"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="text-center mt-sm-4"
              sx={{ width: "400px", color: "black" }}
            >
              {alertvalue}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Button variant="contained" color="error" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <div className="imageback">
          <Dimmer inverted>
            <Loader inline="centered" style={{ color: "black" }} size="massive">
              Loading
            </Loader>
          </Dimmer>
        </div>
      </Card>
    </>
  );
}

{
  /* <DatePicker
showTime={{ format: 'hh:mm A', use12Hours:true }}
 minuteStep={30} 

 format="DD-MM-YYYY "
 value={startValue}
 placeholder="Check-In Date"
 onChange={chkindtchge}

/> */
}
